
body {
	overflow-x: hidden;
}

.App-bg {
	width: 100%;
	height: 100%;
	background-color: #d3dff7;
	background-image: url('bg.jpg');
	background-attachment: fixed;
	background-size: cover;
	filter: saturate(80%) contrast(117%) hue-rotate(-10deg);
	z-index: -1;
	position: fixed;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	will-change: scroll-position;
}

.bg-blur {
	z-index: 99999;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #e0ebf594;
	backdrop-filter: blur(10px) saturate(130%) contrast(110%);
	overflow: hidden;
	opacity: 0;
	animation-name: popup;
	animation-delay: 2s;
	animation-duration: 0s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

.App-logo {
	height: 3.2vw;
	opacity: 0.85;
	filter: saturate(120%) contrast(110%);
	box-sizing: border-box;
	margin: 0px 20px;
}

.App-logo.mobile {
	z-index: 9999;
	top: 0;
	left: 0;
	margin: 3%;
	position: absolute;
	height: 11vw;

}

.hamburger {
	z-index: 9999;
	top: 0;
	right: 0;
	margin: 3%;
	position: absolute;
	height: 11vw;
	font-size: 7vw;
	color: #b5c1c9bb;
	pointer-events: none;
}

.profile {
	left: 0;
	height: 17vw;
	filter: saturate(60%) contrast(80%) hue-rotate(30deg) brightness(115%);
	pointer-events: none;
	box-sizing: border-box;
	margin: 0px 20px;
}

@media (max-width: 768px) {
	.profile {
		height: 60vw;
	}
}

.testimonial-profile {
	left: 0;
	height: 6vw;
	pointer-events: none;
	box-sizing: border-box;
	filter: saturate(80%);
}

@media (max-width: 768px) {
	.testimonial-profile {
		height: 27vw;
	}
}

.link-img {
	height: 1.4vw;
	pointer-events: none;
}

@media (max-width: 768px) {
	.link-img {
		height: 5vw;
		pointer-events: none;
	}
}

.wordcloud {
	width: 60%;
	filter: saturate(80%);
	opacity: 0.8;
	margin-left: 15px;
}

@media (max-width: 768px) {
	.wordcloud {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
}

.gif {
	opacity: 0.5;
	width: 100%;
	filter: hue-rotate(-20deg) contrast(80%) saturate(120%);
	transition: all 0.05s;
}

.gif:hover {
	width: 105%;
	margin: -1% -2.5%;
	transition: all 0.2s;
}

.gif-mask {
	width: 100%;
	color: #8da4b900;
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 60%, transparent 100%);
	transition: all 0.1s;
}

.gif-mask:hover {
	color: #6ea0d1ad;
	transition: all 0.2s;
}

.gif-text {
	position: absolute;
  	top: 17vw;
  	left: 50%;
  	transform: translate(-50%, -50%);
	mix-blend-mode: overlay;
	font-weight: 400;
	font-size: 4vw;
}

.grid {
	z-index: 99;
	position: absolute;
	mix-blend-mode: overlay;
	width: 100%;
	opacity: 0.1;
	transition: all 0.1s;
}

.grid:hover {
	opacity: 0.7;
	transition: all 0.2s;
}

.title {
	font-size: 3.6vw;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	color: #143158e8;
	margin: 15px 15px 0px 15px;
}

@media (max-width: 768px) {
	.title {
		font-size: 10vw;
	}
}

.subtitle {
	margin: 2px 0px 30px 20px;
	font-size: 1.2vw;
	color: #a0500e94;
	font-weight: 400;
}

@media (max-width: 768px) {
	.subtitle {
		margin-top: 20px;
		font-size: 4.5vw;
	}
}

.jobtitle {
	margin: 2px 0px 30px 20px;
	font-size: 1.1vw;
	color: #a0500e94;
	font-weight: 400;
	text-align: left;
}

@media (max-width: 768px) {
	.jobtitle {
		margin-top: 20px;
		font-size: 4.2vw;
		text-align: center;
	}
}

.quote {
	position: absolute;
	margin: 0 2vmin;
	font-size: 3.5vw;
	color: #d8d8d8;
	mix-blend-mode: normal;
	opacity: 0.2;
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-weight: 500;
}

.quote2 {
	position: absolute;
	margin: 0 2vmin;
	font-size: 3.5vw;
	color: #d8d8d8;
	mix-blend-mode: overlay;
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-weight: 500;
}

@media (max-width: 768px) {
	.quote {
		font-size: 8vw;
	}

	.quote2 {
		font-size: 8vw;
	}
}

.text-on-blue {
	position: relative;
	font-size: 1.4vw;
	padding: 20px 20px 20px 40px;
	padding-bottom: 50px;
	color: #a1caf0f3;
	mix-blend-mode: luminosity;
	opacity: 0.8;
	line-height: 120%;
	font-weight: 300;
	text-align: left;
	font-family:'Calibri', sans-serif;
}

@media (max-width: 768px) {
	.text-on-blue {
		font-size: 4vw;
		padding: 15px 15px 15px 30px;
	}
}

.absolute-fix {
	margin-top: 9vw;
}

@media (max-width: 768px) {
	.absolute-fix {
		margin-top: 20vw;
	}
}

.heading-on-white {
	color:#0b2c57e1;
	font-family: 'Tahoma', sans-serif;
	font-weight: 500;
	font-size: 2vw;
	margin: 2px;
}

.subtitle-on-white {
	color:#041e41e1;
	font-family: 'Tahoma', sans-serif;
	font-size: 1.5vw;
	margin: 10px;
	margin-left: 2px;
}

.subtitle-on-white.popup {
	color:#041e41e1;
	font-family: 'Tahoma', sans-serif;
	font-size: 1.3vw;
	margin: 10px;
	margin-left: 2px;
	text-align: left !important;
}

.text-on-white {
	color:#041e41e1;
	font-family: 'Tahoma', sans-serif;
	font-style: normal;
	font-size: 1.1vw;
	line-height: 140%;
	margin-left: 2px;
}

@media (max-width: 768px) {
	.heading-on-white {
		font-size: 6vw;
	}

	.subtitle-on-white {
		font-size: 4.5vw;
	}

	.subtitle-on-white.popup {
		font-size: 4vw;
		text-align: center !important;
	}

	.text-on-white {
		font-size: 3.5vw;
	}
}

.timeline {
	margin-top: 10px;
	margin-bottom: 10px;
}

.nowrap {
	white-space: nowrap;
}

.div-page {
	top: 0;
	left: 0;
	position: relative;
	width: 100%;
}

.vertical-stack {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.vertical-stack-full{
	position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    padding: 0;
	align-items: center;
    box-sizing: border-box;
}

.vertical-stack-full > .box.blue.right {
    margin: 2.5vw 0px 0px 0px !important;
    max-width: 100% !important;
    min-width: 100% !important;
	width: 100% !important;
}

@media (max-width: 768px) {
	.vertical-stack {
		padding: 5px;
	}
}

@media (max-width: 768px) {
	.vertical-stack-full {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
	.vertical-stack-full > .box.blue.right {
		border-radius: 40px 3px 3px 40px !important;
		margin: 7vw -2px 0px 10px  !important;
		min-width: 98%  !important;
		max-width: 100% !important;
	}
}

.vertical-stack.thinner {
	width: 85%;
}

@media (max-width: 768px) {
	.vertical-stack.thinner {
		width: 100%;
	}
}

.vertical-stack.left {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 5vw;
}

.vertical-stack.popup {
	width: 100%;
	height: 100%;
	display: flex;
}

@media (max-width: 768px) {
	.vertical-stack.popup {
		text-align: center;
		align-items: center;
		padding-top: 30px;
		padding-left: 0;
	}
}

.vertical-stack.text {
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-width: 50vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 2vmin 4vmin;
}

@media (max-width: 768px) {
	.vertical-stack.text {
		padding: 10px;
		max-width: 100%;
	}
}

.horizontal-stack {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.horizontal-stack.left {
	justify-content: left;
}

@media (max-width: 768px) {
	.horizontal-stack {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.horizontal-stack.EE {
		flex-direction: row;
		justify-content: center;
	}

	.horizontal-stack.popup {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.horizontal-stack.left {
		flex-direction: column;
		justify-content: center;
	}
}

.horizontal-span {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.horizontal-blank {
	position: relative;
	top: 0;
	left: 0;
	min-width: 50%;
	min-height: 5px;
}

@media (max-width: 768px) {
	.horizontal-blank {
		min-width: 0%;
		right: 0;
		text-align: right;
		display:contents;
	}
}

.horizontal-blank.small {
	position: relative;
	top: 0;
	left: 0;
	min-width: 30%;
	min-height: 20px;
}

.text-span {
	display: inline-block;
	width: 100%;
	flex: 0 0 auto;
	flex-shrink: 1;
}

.gold {
	margin: 15px 15px;
	background-color: #bb6b15bc;
	font-weight: 400;
	min-height: 1.5px;
	max-height: 1.5px;
	width: 100%;
	flex-grow: 1;
}

.gold.left {
	margin: 15px 2px;
}

@media (max-width: 768px) {
	.gold {
		margin: 10px 5px;
	}

	.gold.left {
		margin: 10px 2px;
	}
}

.video {
	width: 100%;
	height: auto;
}

.video.fix {
	transform: translateY(-12%);
}

.image {
	width: 100%;
	height: auto;
}

.media-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.media-container.fix {
	padding: 20px 0px;
	margin-top: 50px;
}

.media-container.small {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	max-width: 90%;
	max-height: 80%;
	border-radius: .5vw;
	overflow: hidden;
}

.box-container {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.reverse {
	flex-direction: row-reverse;
}


@media (max-width: 768px) {
	.box-container {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.box-container.reverse {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.box-container-main {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
}

@media (max-width: 768px) {
	.box-container-main {
		top: 90px;
	}
}

.multi-box-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.multi-box-container {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
}

.box {
	box-sizing: border-box;
	padding: 20px 35px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.box.main {
	border-radius: 25px;
	border-width: 3px 3px 2px 2px;
	border-style: solid;
	background-color: #e7edf8a8;
	box-shadow: -30px 20px 40px 0px rgba(50, 112, 182, 0.432);
	border-color: #f3fbff91;
	min-width: 95vw;
	margin: 2vw;
	margin-bottom: 4vw;
	padding: 0px;
	padding-bottom: 50px;
	backdrop-filter: blur(0px) saturate(150%) contrast(110%);
	opacity: 0.8;
	animation-name: blur-main;
	animation-delay: 0.1s;
	animation-duration: 0.1s;
	animation-timing-function: cubic-bezier(.4,.69,.9,.25);
	animation-fill-mode: forwards;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media (max-width: 768px) {
	.box.main {
		margin-right: 0;
		margin-left: 0;
		border-width: 2px;
		box-shadow: none;
	}
}

.box.blue {
	background: radial-gradient(circle at 100% 10%, #346ab1 5%, #021f4b 100%);
	box-shadow: -15px 10px 15px 0px rgba(12, 71, 160, 0.253);
	border: 0;
	max-width: 45%;
	min-width: 35%;
	min-height: 200px;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
}

.blue.right {
	border-radius: 40px 7px 7px 40px;
	margin: 2.5vw 0px 0px 5px;
}

.blue.left {
	border-radius: 7px 40px 40px 7px;
	margin: 2.5vw 5px 0px 0px;
}

@media (max-width: 768px) {
	.blue.right {
		border-radius: 40px 3px 3px 40px;
		margin: 7vw -2px 0px 10px;
		min-width: 98%;
		max-width: 100% !important;
	}
	.blue.left {
		border-radius: 3px 40px 40px 3px;
		margin: 7vw 10px 0px -2px;
		min-width: 98%;
		max-width: 100% !important;
	}
}

.box.blank {
	box-shadow: -3px 3px 10px 0px rgba(12, 83, 190, 0.027);
	background: linear-gradient(to left, #f2faffec 0%, #f0f4f70c 40%);
	border-width: 2px;
	border-color: #f3fbff33;
	border-radius: 15px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.box.white {
	box-sizing: border-box;
	box-shadow: -5px 5px 10px 0px rgba(16, 110, 197, 0.089);
	background-color: #ffffff8f;
	border-radius: 15px;
	margin: 2.5vw 2.5% 0px 0px;
	padding: 3.5vw;
	backdrop-filter: blur(0px);
	animation-name: blur-white;
	animation-delay: 0s;
	animation-duration: 0.1s;
	animation-timing-function: cubic-bezier(.4,.69,.9,.25);
	animation-fill-mode: forwards;
	top: 0;
	left: 0;
	width: 86%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
}

@media (max-width: 768px) {
	.box.white {
		margin: 7vw 0px 0px 0px;
		padding: 7vw;
		width: 95%;
		text-align: left;
	}
}

.box.white.popup {
	width: 80%;
	border-style: solid;
	border-width: 3px;
	border-color: rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0.756);
	text-align: left !important;
}

@media (max-width: 768px) {
	.box.white.popup {
		width: 100%;
		min-height: 70vh;
		margin: 0;
		padding-top: 10vh;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.box.shrinkwrap {
	width: max-content;
	max-width: 600px;
	flex-grow: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	height: auto;
	min-height: 13vw;
}

@media (max-width: 768px) {
	.box.shrinkwrap {
		width: 95%;
		min-height: 12vh;
	}
}

.box.projects {
	width: 88% !important;
}

@media (max-width: 768px) {
	.box.projects {
		width: 95% !important;
		text-align: center !important;
		align-items: center !important;
		margin-top: 15px;
		margin-bottom: 10px;
	}
}

.box.big {
	min-width: 55%;
}

.box.shorter {
	padding: 2vw 3.5vw;
}

@media (max-width: 768px) {
	.box.shorter {
		padding: 7vw;
	}
}

.left {
	position: relative;
	left: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px 5px 0px 0px;
}

.right {
	position: relative;
	right: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.wide {
	position: relative;
	top: 0;
	left: 0;
	margin: 2.5vw 1vw 0 1vw !important;
	width: 95% !important;
	align-items: center !important;
	justify-content: center !important;
}

@media (max-width: 768px) {
	.wide {
		margin: 7vw 0px 0px 0px !important;
		text-align: center !important;
	}
}

.left-text {
	align-items: flex-start !important;
	text-align: left !important;
	padding-left: 5vw !important;
}

.center-text {
	align-items: center !important;
	text-align: center !important;
}

.testimonial-container {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

ul {
	margin-top: 5px;
	padding-left: 1.1vw;
}

@media (max-width: 768px) {
	ul {
		padding-left: 3vw;
	}
}

.nav {
	top: 0;
	left: 0;
	min-width: 100%;
	height: 5vw;
	z-index: 999;
	position: sticky;
	margin: 0px 0px 0px 0px;
	padding: 10px;
	border-radius: 0px 0px 10px 10px;
	box-shadow: -30px 20px 30px 0px rgba(32, 103, 184, 0.075);
	background-color: #fdfeffe0;
	backdrop-filter: blur(100px) saturate(130%) contrast(110%);
	opacity: 0.8;
	animation-name: blur-nav;
	animation-delay: 0.1s;
	animation-duration: 0.1s;
	animation-timing-function: cubic-bezier(.4,.69,.9,.25);
	animation-fill-mode: forwards;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

nav ul {
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style: none;
	margin-top: 15px;
	padding: 0px 20px;
}

@media (max-width: 768px) {
	nav ul {
		flex-direction: column;
		align-items: flex-start;
	}
}

nav li {
	margin: 0 20px;
	display: inline;
}

@media (max-width: 768px) {
	nav li {
		margin: 15px 0;
		display: flex;
		flex-grow: 1;
	}
}

nav button {
	box-sizing: border-box;
	color: #385883e0;
	font-family: 'Calibri', sans-serif;
	font-weight: 300;
	font-size: 1.5vw;
	background: #ffffff00;
	padding: 6px 10px;
	margin: 0px 0px 7px 0px;
	cursor: pointer;
	position: relative;
	border-style: solid;
	border-color: transparent;
	border-radius: 2px;
	border-width: 0px 0px 2px 0px;
}

@media (max-width: 768px) {
	nav button {
		width: 100%;
		margin: 0;
		font-size: 6vw;
	}
}

nav button:hover {
	border-color: rgba(247, 200, 133, 0.356);
	border-radius: 10px;
	background-color: #f7fcfda6;
	transition: background-color 0.3s;
	transition: border-color 0.3s;
	transition: border-radius 0.15s;
}
  
nav button.active {
	font-weight: 400;
	border-width: 0px 0px 2px 0px;
	border-color: rgba(246, 179, 34, 0.953);
	border-radius: 0px;
}

.link{
	box-sizing: border-box;
	color: #385883e0;
	min-width: 80px;
	font-family: 'Calibri', sans-serif;
	font-weight: 300;
	font-size: 1.3vw;
	background: #d1e6f059;
	padding: 6px 10px;
	margin: 0px 0px 7px 0px;
	cursor: pointer;
	position: relative;
	border-style: solid;
	border-color: transparent;
	border-radius: 10px;
}

@media (max-width: 768px) {
	.link {
		width: 100%;
		margin: 0;
		font-size: 6vw;
	}
}

.mobile-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 100%;
	height: 800px;
	z-index: 999;
	margin: 0;
	padding: 0;
	padding-bottom: 10vh;
	box-shadow: -30px 20px 30px 0px rgba(32, 103, 184, 0.075);
	background-color: #fdfeffbe;
	backdrop-filter: blur(20px) saturate(130%) contrast(110%);
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	overflow: hidden;
  }

  .mobile-nav-items {
	top: 100%;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
  }

.mobile-nav.closed {
	transform: translateY(-92%);
	transition: transform 0.3s;
}

.mobile-nav.open {
	transform: translateY(0%);
	transition: transform 0.3s;
	overflow: hidden;
}

.button.popup {
	color: #243f61de;
	min-width: 18vw;
	font-family: 'Dubai', sans-serif;
	font-weight: 400;
	font-size: 1.8vw;
	background: #ffffff;
	box-shadow: 0px 3px 5px 2px rgba(36, 53, 68, 0.096);
	border-style: solid;
	border-width: 2px;
	border-color: rgba(143, 164, 172, 0.079);
	padding: 6px 10px;
	margin: 0px 0px 7px 0px;
	cursor: pointer;
	position: relative;
	border-radius: 5px;
}

.button.popup:hover {
	background: #e7ecee00;
	transition: all 0.2s;
}

@media (max-width: 768px) {
	.button.popup {
		min-width: 100%;
		width: 100%;
		margin: 0;
		font-size: 6vw;
		flex-grow: 1;
		padding-left: 20vw;
		padding-right: 20vw;
	}
}

.button.doc {
	color: #385883e0;
	min-width: 80px;
	font-weight: 300;
	font-size: 1.3vw;
	background: #e3e8eead;
	padding: 6px 6px;
	margin: 10px 15px 0px 0px;
	cursor: pointer;
	position: relative;
	border-style: solid;
	border-color: transparent;
	border-radius: 10px;
}

@media (max-width: 768px) {
	.button.doc {
		width: 100%;
		margin: 10px 0;
		font-size: 6vw;
	}
}

@keyframes popup {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}


@keyframes blur-main {
	from {
		backdrop-filter: blur(0px) saturate(200%) contrast(110%);
		opacity: 0.8;
	}
	to {
		backdrop-filter: blur(100px) saturate(150%) contrast(110%);
		opacity: 1;
	}
}

@keyframes blur-nav {
	from {
		backdrop-filter: blur(0px) saturate(200%) contrast(110%);
		opacity: 0.8;
	}
	to {
		backdrop-filter: blur(70px) saturate(130%) contrast(110%);
		opacity: 1;
	}
}

@keyframes blur-white {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(5px);
	}
}
